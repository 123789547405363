<template>
  <div class="container">
	<!-- 设置默认交易所 -->
	<!-- <common-head :title="$t('system.defaultExchange')" :is-return="true"></common-head> -->
	<van-nav-bar
	    :title="$t('system.kuaijiekaihu')"
	    left-arrow
	    @click-left="onClickLeft"
	    safe-area-inset-top
	    fixed
	    z-index="9999"
	/>
	<div class="g_safe_top">
		<div class="layoutBox bgWhite">
				<div class="list-radio-item" @click="detailsTo('huobi')">
					<div class="label">
						<div class="icon"><img src="@/assets/imgs/new_color/api-huobi.png" alt=""></div>
						{{ $t('system.huobi') }}
					</div>
					<div class="right_img">
						<img src="../../assets/imgs/new_color/tiaozhuan_r.png" >
					</div>
					<!-- <van-radio name="huobi" /> -->
				</div>
				<div class="list-radio-item" @click="detailsTo('bian')">
					<div class="label">
						<div class="icon"><img src="@/assets/imgs/new_color/api-bian.png" alt=""></div>
						{{ $t('system.bian') }}
					</div>
					<div class="right_img">
						<img src="../../assets/imgs/new_color/tiaozhuan_r.png" >
					</div>
					<!-- <van-radio name="bian" /> -->
				</div>
				<div class="list-radio-item" @click="detailsTo('okex')">
					<div class="label">
						<div class="icon"><img src="@/assets/imgs/new_color/api-okex.png" alt=""></div>
						{{ $t('system.OKEx') }}
					</div>
					<div class="right_img">
						<img src="../../assets/imgs/new_color/tiaozhuan_r.png" >
					</div>
					<!-- <van-radio name="okex" /> -->
				</div>
		</div>
	</div>
	
  </div>
</template>

<script>
import commonHead from '@/components/commonHead';

export default {
  name: "kaihu",
  components: { 
	  commonHead,	  
  },
  data() {
    return {
		exchange: '',
		html_url: '',
		list: []
    };
  },
  created() {
	  this.choiceExchange();
  },
  methods: {
	  //  获取外部网页地址
	  choiceExchange() {
	  	this.$post2('/User/Api/Passport/choiceExchange ', {})
	  		.then(res => {
	  			this.list = res;
	  		})
	  		.catch(e => {
	  			console.log(e);
	  		});
	  },
    // onClickLeft  点击返回“设置”页面
    onClickLeft() {
      this.$router.back(-1);
    },
	// 点击跳转到k开户页面
	detailsTo(e) {
		this.exchange = e;
		if(this.exchange == 'huobi') {
			this.html_url = this.list.huobi
		}else if(this.exchange == 'bian') {
			this.html_url = this.list.bian
		}else if(this.exchange == 'okex') {
			this.html_url = this.list.okex
		}
		// this.$router.push({
		// 	path: 'kaihu2',
		// 	query: {
		// 		exchange: e
		// 	}
		// });
		window.open(this.html_url, '_blank');
	},
  },
};
</script>

<style scoped lang="less">
.backgrondRoade {
  width: 0.6rem;
  background: black;
  height: 0.6rem;
}

.duihaoImg {
  width: 0.3rem;
  margin-left: 5.6rem;
}

/* @import "../../assets/css/puliceStyle.css"; */
.body1StyleInputStyle {
  border: 0px;
}

.bodyStyleDivStyleP {
  margin-top: 0.3rem;
  margin-bottom: 0.2rem;
}

.bodyStyleDivStyle {
  border-bottom: 1px solid #fbfbfd;
  padding-bottom: 0.3rem;
}

.body1Style {
  padding: 0.3rem;
}


.title {
  background: #f6f8f9;
  height: 2rem;
  padding: 0.3rem;
}

.bottomBtnStyle {
  width: 100%;
  background: #e1c364;
  border-radius: 5px;
  border: 0px;
}

.titleGuanLiStyle {
  font-size: 0.4rem;
  padding-top: 1.5rem;
}

.titlePStyle {
  margin-bottom: 0.2rem;
  margin-top: 0.3rem;
  padding-top: 0.1rem;
  transform: rotate(180deg);
}

/* ---------20200411[start]------- */
.layoutBox {
	height: 100vh;
	margin-top: 0.2rem;
	min-height:91.8vh;
	border-top-left-radius: 0.32rem;
	border-top-right-radius: 0.32rem;
	padding: 0 0.2rem;
	font-family: PingFangSC-Regular;
}

.list-radio-item {
	padding: .2rem 0;
	margin: 0 0.2rem;
	height: 1.2rem;
	border-bottom: 1px solid #EDEDED;
	display: flex;
	justify-content: space-between;
	align-items: center;
	.label {
		color: #333333;
		font-size: .28rem;
		display: flex;
		align-items: center;
	}
	.icon {
		width: .7rem;
		height: .7rem;
		margin-right: .15rem;
		img {
			display: block;
			width: 100%;
		}
	}
	.right_img {
		img {
			width: 0.14rem;
			height: 0.24rem;
		}
	}
}
.comBtnStyle {
	height: 0.8rem;
	line-height: 0.8rem;
	margin: 0 0.2rem;
}
/* ---------20200411[end]------- */
</style>
